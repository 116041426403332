import React, { Component, Fragment } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";

import { ThemeProvider } from "@material-ui/styles";
import theme from "./components/UI/Theme";
import {
  changeActiveTab,
  onTryAutoLogin,
  logoutUser,
} from "./App/redux/action";
import { referalCode } from "./containers/Screens/OtpVerification/redux/action";
import { connect } from "react-redux";

import SubmitBillContainer from "./containers/Screens/SubmitBill/SubmitBillContainer";
import RedeemContainer from "./containers/Screens/Redeem/RedeemContainer";

import LoginContainer from "./containers/Screens/Login/LoginContainer";
import OtpVerificationContainer from "./containers/Screens/OtpVerification/OtpVerificationContainer";
import MyCartContainer from "./containers/Screens/MyCart/MyCartContainer";
import MyMembershipContainer from "./containers/Screens/MyMembership/MyMembershipContainer";
import RegistrationContainer from "./containers/Screens/Registration/RegistrationContainer";
import BillSummaryContainer from "./containers/Screens/BillSummary/BillSummaryContainer";
import TransactionContainer from "./containers/Screens/Transactions/TransactionContainer";
import DashboardContainer from "./containers/Screens/Dashboard/DashboardContainer";
import ProductDetailsContainer from "./containers/Screens/ProductDetails/ProductDetailsContainer";
import OrderSummaryContainer from "./containers/Screens/OrderSummary/OrderSummaryContainer";
// import SplashContainer from "./containers/Screens/Splash/SplashContainer";
import {
  getStorageItem,
  setStorageItem,
  removeStorageItem,
} from "./common/localStorage";
import OffersContainer from "./containers/Screens/Offers/OffersContainer";
import MoreContainer from "./containers/Screens/More/MoreContainer";
import ProfileContainer from "./containers/Screens/Profile/ProfileContainer";
import BookVisitContainer from "./containers/Screens/BookVisit/BookVisitContainer";

import Loader from "./components/Loader/Loader";
import Optout from "./components/Optout";
import Maintainance from "./components/Maintainance";
import AboutUs from "./containers/Screens/More/components/AboutUs";
import ContactUs from "./containers/Screens/More/components/ContactUs";
import TermsOfUse from "./containers/Screens/More/components/TermsOfUse";
import HowItWorks from "./containers/Screens/More/components/HowItWorks";
import FAQs from "./containers/Screens/More/components/FAQs";
import FeedbackForm from "./containers/Screens/More/components/Feedback/FeedbackForm";
import StoreLocator from "./containers/Screens/More/components/StoreLocator";

import EditProfileContainer from "./containers/Screens/Profile/EditProfileContainer";
import InstructionDialog from "./components/InstructionDialog/InstructionDialog";
import MiBrowserDialog from "./components/MiBrowserDialog/MiBrowserDialog";
import ThankYouContainer from "./containers/Screens/ThankYou/ThankYouContainer";

import {
  clearOffers,
  clearOfferDetails,
} from "./containers/Screens/Offers/redux/actions";
import { clearBrands } from "./containers/Screens/More/components/StoreLocator/redux/actions";
import platform from "platform";
import BillUploadProgressContainer from "./containers/Screens/BillUploadProgress/BillUploadProgressContainer";
import BillUploadGuidelinesContainer from "./containers/Screens/BillUploadGuidelines/BillUploadGuidelinesContainer";
import NotificationsContainer from "./containers/Screens/Notifications/NotificationsContainer";
import { stockAPI } from "./common/axiosInstance";
import { openAlertBox } from "./common/AlertMessage/AlertMessage";
import HandleOreintations from "./hoc/HandleOreintations";
import ReferFriendContainer from "./containers/Screens/ReferFriend/components/ReferFriendContainer";
import * as serviceWorker from './serviceWorker';
import swConfig from './swConfig';
import { Button } from "@material-ui/core";
import { apiData } from "./common/common-types";

let interval = null;
// const isMiBrowser = platform.product === 'Xiaomi MiuiBrowser';
const isMiBrowser = /miuibrowser/.test(navigator.userAgent.toLowerCase());
class App extends Component {
  installPrompt = null;
  state = {
    instructionDialog: false,
    isMiBrowserDialog: isMiBrowser,
    warningDialog: false,
    width: 0,
    height: 0,
    orientation: "",
    angle: 0,
    newVersionAvailable: false,
    ismaintainance:
      (!getStorageItem("ismaintainance") ||
        getStorageItem("ismaintainance") === "false"
        ? false
        : true) || false,
  };

  componentDidMount() {
    setStorageItem("IsTabActive", "true");
    if (process.env.NODE_ENV === 'production') {
      serviceWorker.register({ onUpdate: this.OnServiceWorkerUpdate });
    }
    this.getReferalCode();
    window.addEventListener("contextmenu", (e) => {
      //to avoid user to mouse right click
      e.preventDefault();
    });
    window.addEventListener("storage", function (e) {
      if (e.storageArea.length === 0) {
        window.open(window.origin + "/login", "_self");
      }
    });
    this.getMaintainance();
    interval = setInterval(() => {
      this.getMaintainance();
    }, 60000);

    //this function will set the active tab in app
    // this.props.setActiveTab();
    //this will check if the user has already been logged in
    //and allow the user to access the app if logged in else logout

    this.props.onTryAutoLogin();

    //for installation prompt counter
    if (!getStorageItem("promptCounter")) {
      setStorageItem("promptCounter", 0);
    }
    this.installationPrompt();

    window.addEventListener("beforeunload", this.pageRefreshed);

    if (!getStorageItem("isPWAInstalled")) {
      setStorageItem("isPWAInstalled", "not installed");
    }
    if (!window.location.pathname.split("/").includes("optout")) {
      localStorage.removeItem("isOptIn");
      localStorage.removeItem("isconfirm");
    }
  }
  async getReferalCode() {
    let url = window.location.href;
    let updateUrl = url.split("?ref=");
    // let referalCode = updateUrl[1] == undefined ? "false: updateUrl[1]
    let referalCode = updateUrl[1] ? updateUrl[1] : false;
    await setStorageItem("referalCode", referalCode);
  }

  // refreshAction = (key) => { //render the snackbar button
  //   return (
  //     <Fragment>
  //       <Button
  //         style={{
  //           fontStretch: "normal",
  //           fontStyle: "normal",
  //           letterSpacing: "normal",
  //           textAlign: "center",
  //           // color: theme.palette.typography.texts.color,
  //           fontSize: "9px",
  //         }}
  //         // className="snackbar-button"
  //         size="small"
  //         onClick={this.updateServiceWorker}
  //       >{"refresh"}</Button>
  //     </Fragment>
  //   );
  // };
  OnServiceWorkerUpdate = async registration => {
    const { enqueueSnackbar } = this.props;
    const { newVersionAvailable } = this.state
    await this.setState({
      waitingWorker: registration && registration.waiting,
      newVersionAvailable: true
    });
    let newVersion = this.state.newVersionAvailable
    if (newVersion) {//show snackbar with refresh button
      // enqueueSnackbar("A new version was released", {
      //   persist: true,
      //   variant: "success",
      //   action: this.refreshAction(),
      // });
      this.updateServiceWorker()
    }
  }
  updateServiceWorker = () => {
    const { waitingWorker } = this.state
    waitingWorker && waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    this.setState({ newVersionAvailable: false })
    window.location.reload();
  }
  componentDidUpdate(preProps) {
    if (
      !getStorageItem("user") &&
      !preProps.location.pathname.includes("otpverification") &&
      !preProps.location.pathname.includes("registration") &&
      !preProps.location.pathname.includes("login") &&
      !(preProps?.activeTab == 4 && preProps.location.pathname.includes("more"))
    ) {
      setStorageItem("prev_route", preProps.location.pathname);
    }
    if (
      preProps.location.pathname.includes("store-locator") &&
      !this.props.location.pathname.includes("store-locator")
    ) {
      removeStorageItem("brandid");
      this.props.clearBrands();
    }
    // if (
    //   !this.props.location.pathname.includes("offers") &&
    //   !this.props.location.pathname.includes("offers-detail")
    // ) {
    //   removeStorageItem("offerid");
    //   this.props.clearOfferDetails();
    //   this.props.clearOffers();
    // }
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.pageRefreshed);
    window.removeEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
    clearInterval(interval);
    localStorage.removeItem("isOptIn");
    localStorage.removeItem("isconfirm");
  }

  getMaintainance() {
    const formData = new URLSearchParams();
    formData.append("merchant_id",apiData.merchant_id&&apiData.merchant_id);
    stockAPI(formData, "POST", "/isMaintainance")
      .then((response) => {
        if (response) {
          setStorageItem("ismaintainance", response.maintainance_mode);
          this.setState({
            ismaintainance: response.maintainance_mode,
          });
        }
      })
      .catch((err) => {
        this.setState({
          ismaintainance: false,
        });
        // openAlertBox(err && err.message, "error");
      });
  }

  pageRefreshed = async (e) => {
    if (this.props.uploadingStatus && this.props.uploadPercentage !== 100) {
      e.preventDefault();
      e.returnValue = "";
    }
  };

  closeInstructionDialog = () => {
    this.setState(
      {
        instructionDialog: false,
      },
      () => {
        setStorageItem(
          "promptCounter",
          parseInt(getStorageItem("promptCounter")) + 1
        );
      }
    );
  };

  closeMiBrowserDialog = () => {
    this.setState({ isMiBrowserDialog: false });
  };

  openInstructionDialog = () => {
    if (
      !this.installPrompt &&
      getStorageItem("promptCounter") &&
      parseInt(getStorageItem("promptCounter")) < 1 &&
      !(
        (window.matchMedia &&
          window.matchMedia("(display-mode: standalone)").matches) ||
        window.navigator.standalone === true
      ) &&
      getStorageItem("isPWAInstalled") === "not installed" &&
      !isMiBrowser
    ) {
      this.setState({
        instructionDialog: true,
      });
    }
  };

  installationPrompt = () => {
    if (
      getStorageItem("promptCounter") &&
      parseInt(getStorageItem("promptCounter")) < 3
    ) {
      window.addEventListener("beforeinstallprompt", (e) => {
        // For older browsers
        e.preventDefault();
        this.installPrompt = e;
        // See if the app is already installed, in that case, do nothing
        if (
          (window.matchMedia &&
            window.matchMedia("(display-mode: standalone)").matches) ||
          window.navigator.standalone === true
        ) {
          return false;
        }
      });
    }
  };

  installApp = async () => {
    if (
      getStorageItem("promptCounter") &&
      parseInt(getStorageItem("promptCounter")) < 3
    ) {
      if (!this.installPrompt) {
        this.openInstructionDialog();
        return false;
      }
      this.installPrompt
        .prompt()
        .then((response) => {
          if (response.outcome === "dismissed") {
            setStorageItem(
              "promptCounter",
              parseInt(getStorageItem("promptCounter")) + 1
            );
            console.log("User cancelled installation");
          } else {
            console.log("User added to home screen");
            setStorageItem("promptCounter", 3);
          }
          this.installPrompt = null;
        })
        .catch((err) => {
          this.installPrompt = null;
        });
    }
  };

  changeActiveTab = (index) => {
    this.props.changeActiveTab(index);
  };

  render() {
    const { showLoader } = this.props;
    const { ismaintainance } = this.state;
    let routes = (
      <Switch>
        <Route path="/otpverification" component={OtpVerificationContainer} />
        <Route path="/registration" component={RegistrationContainer} />
        <Route path="/terms-of-use" component={TermsOfUse} />
        <Route path="/visit" component={BookVisitContainer} />
        <Route path="/store-locator" component={StoreLocator} />
        <Route path="/store-locator/:brandid" component={StoreLocator} />
        <Route path="/store-locator/floor-plan" component={StoreLocator} />
        <Route path="/login" component={LoginContainer} />
        <Route path="/optout" component={Optout} />
        <Route path="/faqs" component={FAQs} />
        <Redirect to={{ pathname: "/login" }} />
        {/* {!(
					(window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) ||
					window.navigator.standalone === true
				) && <Route path="/" component={SplashContainer} />} */}
        <Route
          path="/"
          render={() => {
            return <Redirect to="/" />;
          }}
        />
      </Switch>
    );

    //this will check if the user is logged
    if (this.props.isAuthenticated) {
      routes = (
        <Fragment>
          <Switch>
            <Route path="/transactions" component={TransactionContainer} />
            <Route path="/dashboard">
              <DashboardContainer installApp={this.installApp} />
            </Route>
            <Route path="/redeem" component={RedeemContainer} />
            <Route path="/product/:id" component={ProductDetailsContainer} />
            <Route path="/myCart" component={MyCartContainer} />
            <Route path="/offers" component={OffersContainer} />
            <Route path="/more" component={MoreContainer} />
            <Route path="/membership" component={MyMembershipContainer} />
            <Route path="/submit-bill" component={SubmitBillContainer} />
            <Route path="/bill-summary" component={BillSummaryContainer} />
            <Route path="/order-summary" component={OrderSummaryContainer} />
            <Route path="/about-us" component={AboutUs} />
            <Route path="/contact-us" component={ContactUs} />
            <Route path="/terms-of-use" component={TermsOfUse} />
            <Route path="/how-it-works" component={HowItWorks} />
            <Route path="/store-locator" component={StoreLocator} />
            <Route path="/store-locator/:brandid" component={StoreLocator} />
            <Route path="/refer-friend" component={ReferFriendContainer} />

            <Route
              path="/store-locator/floor-plan/:floorid"
              component={StoreLocator}
            />
            <Route path="/faqs" component={FAQs} />
            <Route path="/feedback" component={FeedbackForm} />
            <Route path="/my-profile" component={ProfileContainer} />
            <Route path="/visit" component={BookVisitContainer} />
            <Route path="/edit-profile" component={EditProfileContainer} />
            <Route path="/thank-you" component={ThankYouContainer} />
            <Route path="/optout" component={Optout} />
            <Route
              path="/bill-upload-guidelines"
              component={BillUploadGuidelinesContainer}
            />
            <Route
              path="/bill-upload"
              component={BillUploadProgressContainer}
            />
            <Route path='/notifications' component={NotificationsContainer} />
            <Route path='/notifications/:id' component={NotificationsContainer} />
            <Route path="/offers-detail/:id" component={OffersContainer} />

            <Route
              path="/"
              render={() => {
                return <Redirect to="/dashboard" />;
              }}
            />
            <Redirect to="/" />
          </Switch>
          <InstructionDialog
            isOpen={
              this.state.instructionDialog && !this.state.isMiBrowserDialog
            }
            handleClose={this.closeInstructionDialog}
          />
        </Fragment>
      );
    }
    let orientationArray = [
      "/login",
      "/optout",
      "/otpverification",
      "/registration",
      "/contact-us",
    ];

    let styling = {
      height: "100%",
      backgroundColor: "#f5f5f5",
    };

    return (
      <ThemeProvider theme={theme}>
        <HandleOreintations
          changeDisplay={orientationArray.includes(
            this.props.history.location.pathname
          )}
        >
          <div style={styling}>
            <Loader open={showLoader} />
            {ismaintainance ? <Maintainance /> : routes}
            <MiBrowserDialog
              isOpen={this.state.isMiBrowserDialog}
              handleClose={this.closeMiBrowserDialog}
            />
          </div>
        </HandleOreintations>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeTab: state.reducerApp.activeTab,
    showLoader: state.reducerApp.showLoader,
    isAuthenticated: state.reducerOtpverification.isAuthenticated,
    uploadingStatus: state.reducerBillUploadProgress.uploadingStatus,
    uploadPercentage: state.reducerBillUploadProgress.uploadPercentage,
    referalCodeNumber: state.reducerOtpverification.referalCode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeActiveTab: (id) => dispatch(changeActiveTab(id)),
    onTryAutoLogin: () => dispatch(onTryAutoLogin()),
    logoutUser: () => dispatch(logoutUser()),
    clearOffers: () => dispatch(clearOffers()),
    clearOfferDetails: () => dispatch(clearOfferDetails()),
    clearBrands: () => dispatch(clearBrands()),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
