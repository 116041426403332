import React, { Component, Fragment } from "react";

import { headerScreenLabels } from "../../../common/common-types";
import Header from "../../../components/Layout/Header/HeaderSubmitBill";
import MyRoute from "../../../hoc/MyRoute";
import SubmitBill from "./components/SubmitBill";
import AlertMessage from "../../../common/AlertMessage/AlertMessage";
import { changeActiveTab, logoutUser } from "../../../../src/App/redux/action";
import { updateSubmitBillDoneBtn } from "./redux/action";
import {
  uploadImages,
  updateImagesToUpload,
  showProgress,
} from "../BillUploadProgress/redux/action";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import Footer from "../../../components/Layout/Footer/Footer";
import PendamicPopupModal from "../../../components/PendamicPopup/PendamicPopupModal";
import { merchantDetails } from "../../Screens/Dashboard/redux/action";
const mapStateToProps = (state) => {
  return {
    uploadingStatus: state.reducerBillUploadProgress.uploadingStatus,
    isShortBill: state.reducerSubmitBill.isShortBill,
    merchantDetailsResponse: state.reducerDashboard.merchantDetailsResponse,
  };
};

const mapDispatchToProps = {
  changeActiveTab,
  merchantDetails,
  logoutUser,
  updateSubmitBillDoneBtn,
  uploadImages,
  updateImagesToUpload,
  showProgress,
};

class SubmitBillContainer extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.changeActiveTab(0);
    this.setState({ isModalVisible: this.props?.merchantDetailsResponse?.is_earn_burn_enabled })
  }

  render() {
    return (
      <Fragment>


        <Header screenLabel={headerScreenLabels.submitBill} />

        <AlertMessage />
        <MyRoute billUploadStatus={this.props.uploadingStatus}>

          <SubmitBill
            logoutUser={this.props.logoutUser}
            updateSubmitBillDoneBtn={this.props.updateSubmitBillDoneBtn}
            uploadImages={this.props.uploadImages}
            updateImagesToUpload={this.props.updateImagesToUpload}
            showProgress={this.props.showProgress}
            isShortBill={this.props.isShortBill}
          />
        </MyRoute>
        <Grid item>
          <Footer />
        </Grid>
        <PendamicPopupModal
          isVisible={this.props.merchantDetailsResponse&&this.props.merchantDetailsResponse.is_earn_enabled}
          merchantDetails={this.props.merchantDetailsResponse}
          message={this.props.merchantDetailsResponse&&this.props.merchantDetailsResponse.earn_restriction_message}
        />


      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmitBillContainer);
