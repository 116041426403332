import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import Header from "../../../../components/Layout/Header/HeaderSubmitBill";
import { headerScreenLabels, apiData } from "../../../../common/common-types";
import MyRoute from "../../../../hoc/MyRoute";
import AlertMessage, {
  openAlertBox,
} from "../../../../common/AlertMessage/AlertMessage";
import { getStorageItem } from "../../../../common/localStorage";
import {
  changeActiveTab,
  logoutUser,
  showLoader,
} from "../../../../App/redux/action";
import { stockAPI } from "../../../../common/axiosInstance";
import { Grid, Paper, withStyles, Tabs, Tab } from "@material-ui/core";
import {
  faq_qna_backgroundColor,
  lightGrey,
} from "../../../../components/UI/Theme";
import NoRecordFound from "../../../../components/NoRecordFound/NoRecordFound";

const styles = (theme) => {
  return {
    pageContainer: {
      backgroundColor: theme.palette.common.globalBackground,
      height: "100%",
      width: "100%",
      display: "block",
      textAlign: "left",
      overflowY: "auto",
    },
    innerContainer: {
      padding: "0px 30px 0px 30px",
      [theme.breakpoints.down("641")]: {
        padding: "0px 28px 0px 26px",
      },
      [theme.breakpoints.down("481")]: {
        padding: "0px 20px 0px 20px",
      },
      [theme.breakpoints.down("381")]: {
        padding: "0px 15px 0px 15px",
      },
      [theme.breakpoints.down("321")]: {
        padding: "0px 13px 0px 13px",
      },
    },
    paper: {
      padding: "0px 5px 5px 5px",
      marginBottom: "15px",
    },
    Q_left: {
      backgroundColor: faq_qna_backgroundColor,
      color: theme.palette.primary.main,
      borderRadius: "4px",
      fontWeight: "500",
      padding: "8px",
      textAlign: "center",
      lineHeight: "1.2",
      fontSize: "18px",
      [theme.breakpoints.down("641")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
      },
    },
    A_right: {
      padding: "10px",
      color: lightGrey,
      lineHeight: "1.2",
      textAlign: "justify",
      fontSize: "16px",
      [theme.breakpoints.down("641")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "10.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "8px",
      },
      "& p": {
        margin: 0,
      },
      "& ul": {
        marginTop: "5px",
        marginBottom: 0,
      },
    },
    Q_right: {
      padding: "10px",
      fontWeight: "500",
      color: theme.palette.typography.texts.color,
      textAlign: "justify",
      fontSize: "18px",
      lineHeight: "1.2",
      [theme.breakpoints.down("641")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
      },
    },
    mT: {
      paddingTop: "5px",
    },
    tabContainer: {
      borderRadius: 0,
      "& .MuiTab-root": {
        minHeight: "40px",
        padding: "14px",
        [theme.breakpoints.down(381)]: {
          padding: "11px",
        },
        [theme.breakpoints.down(321)]: {
          padding: "0px",
        },
      },
      "& .MuiTabs-flexContainer": {
        height: "92px",
        fontSize: "16px",
        [theme.breakpoints.down("641")]: {
          height: "86px",
          fontSize: "14px",
        },
        [theme.breakpoints.down("481")]: {
          height: "54px",
          fontSize: "12px",
        },
        [theme.breakpoints.down("421")]: {
          fontSize: "11.5px",
        },
        [theme.breakpoints.down("401")]: {
          fontSize: "11px",
        },
        [theme.breakpoints.down("381")]: {
          height: "46px",
          fontSize: "10.5px",
        },
        [theme.breakpoints.down("361")]: {
          fontSize: "10px",
        },
        [theme.breakpoints.down("321")]: {
          height: "40px",
          fontSize: "8px",
        },
      },
      "& .MuiTabs-scroller": {
        height: "92px",
        fontSize: "16px",
        [theme.breakpoints.down("641")]: {
          height: "86px",
          fontSize: "14px",
        },
        [theme.breakpoints.down("481")]: {
          height: "54px",
          fontSize: "12px",
        },
        [theme.breakpoints.down("421")]: {
          fontSize: "11.5px",
        },
        [theme.breakpoints.down("401")]: {
          fontSize: "11px",
        },
        [theme.breakpoints.down("381")]: {
          height: "46px",
          fontSize: "10.5px",
        },
        [theme.breakpoints.down("361")]: {
          fontSize: "10px",
        },
        [theme.breakpoints.down("321")]: {
          height: "40px",
          fontSize: "8px",
        },
      },
      margin: "0 auto",
      width: "100%",
      marginBottom: theme.spacing(2),
      height: "92px",
      fontSize: "16px",
      [theme.breakpoints.down("641")]: {
        height: "86px",
        fontSize: "14px",
      },
      [theme.breakpoints.down("481")]: {
        height: "54px",
        fontSize: "12px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down("381")]: {
        height: "46px",
        fontSize: "10.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("321")]: {
        height: "40px",
        fontSize: "8px",
      },
    },
    tab: {
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textTransform: "none",
      textAlign: "center",
      opacity: 1,
      fontSize: "20px",
      color: "#000000",
      [theme.breakpoints.down("641")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
      },
    },
    w100: {
      width: "100%",
    },
    w25: {
      width: "25%",
    },
    w33: {
      width: "33.33%",
    },
    w50: {
      width: "50%",
    },
    wAuto: {
      width: "auto",
    },
    indicator: {
      backgroundColor: theme.palette.primary.main,
    },
    singleIndicator: {
      backgroundColor: theme.palette.primary.main,
      left: "0 !important",
      width: "100% !important",
    },
    noData: {
      padding: "10px",
      fontWeight: "500",
      margin: "5rem auto",
      color: theme.palette.typography.texts.color,
      fontSize: "20px",
      textAlign: "center",
      lineHeight: "1.5",
      [theme.breakpoints.down("641")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "16px",
        lineHeight: "1.5",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "15.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down("381")]: {
        lineHeight: "1.2",
        fontSize: "14.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("321")]: {
        lineHeight: "1.2",
        fontSize: "12px",
      },
    },
  };
};
class FAQsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      tabsArray: [],
      activeTabData: [],
      currentTab: "",
    };
  }
  async componentDidMount() {
    this.props.changeActiveTab(4);
   
      // this.props.showLoader(true);
      const formData = new URLSearchParams();
      formData.append("platform", apiData.platform);
      formData.append("customer_id", JSON.parse(getStorageItem("user")).id);
      formData.append("merchant_id", apiData.merchant_id);
      let url = "/getFaqList";
      await stockAPI(formData, "POST", url, null, null, undefined, true).then(
        (res) => {
          if (res.statusCode === 100 && res.response) {
            Object.keys(res.response).map((keys) => {
              this.setState({
                [keys.trim()]: [res.response[keys.trim()]],
              });
            });
            this.setState({
              tabsArray: Object.keys(res.response),
              activeTabData: res.response[Object.keys(res.response)[0]],
              currentTab: Object.keys(res.response)[0],
            });
            // this.props.showLoader(false);
          } else {
            openAlertBox("Oops something went wrong!", "error");
          }
        }
      );
   
  }

  handleTabChange = (e, tabNumber) => {
    switch (tabNumber) {
      case tabNumber: {
        this.setState({
          activeTab: tabNumber,
          activeTabData: this.state[this.state.tabsArray[tabNumber]][0],
          currentTab: this.state.tabsArray[tabNumber],
        });
        break;
      }
      default:
        break;
    }
  };

  render() {
    const { classes } = this.props;
    const { activeTabData, activeTab, tabsArray } = this.state;
    const tabContainer = (
      <Paper className={classes.tabContainer}>
        <Tabs
          classes={{
            indicator:
              tabsArray && tabsArray.length === 1
                ? classes.singleIndicator
                : classes.indicator,
          }}
          value={activeTab}
          onChange={this.handleTabChange}
          centered
          scrollButtons="on"
        >
          {tabsArray.map((tabName) => (
            <Tab
              label={tabName}
              className={classnames(classes.tab, {
                [classes.w100]: tabsArray && tabsArray.length === 1,
                [classes.w50]: tabsArray && tabsArray.length === 2,
                [classes.w33]: tabsArray.length === 3,
                [classes.wAuto]: tabsArray.length > 3,
              })}
              disableRipple
            />
          ))}
        </Tabs>
      </Paper>
    );
    if (!tabsArray) {
      return (
        <Fragment>
          <Header screenLabel={headerScreenLabels.faqs} />
          <AlertMessage />
          <MyRoute
            fullHeight={true}
            billUploadStatus={this.props.uploadingStatus}
          >
            <div className={classes.pageContainer}>
              <Grid className={classes.noData}>No data found</Grid>
            </div>
          </MyRoute>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <Header screenLabel={headerScreenLabels.faqs} />
        <AlertMessage />
        <MyRoute
          fullHeight={true}
          billUploadStatus={this.props.uploadingStatus}
        >
          <div className={classes.pageContainer}>
            {tabsArray && tabsArray.length > 0 ? (
              <Grid>{tabContainer}</Grid>
            ) : (
              // <Grid className={classes.noData}>Loading...</Grid>
              <NoRecordFound message={"No data found"} />
            )}
            <div className={classes.innerContainer}>
              {activeTabData &&
                activeTabData.length > 0 &&
                activeTabData.map((item) => {
                  return (
                    <Paper key={item.id} className={classes.paper}>
                      <Grid container className={classes.mT}>
                        <Grid item xs={1} className={classes.Q_left}>
                          Q
                        </Grid>
                        <Grid item xs={11} className={classes.Q_right}>
                          {item.question}
                        </Grid>
                      </Grid>
                      <Grid container className={classes.mT}>
                        <Grid item xs={1} className={classes.Q_left}>
                          A
                        </Grid>
                        <Grid
                          item
                          xs={11}
                          className={classes.A_right}
                          // className={classes.descriptionClass}
                          dangerouslySetInnerHTML={{
                            __html: item.answer
                              ? item.answer
                              : " <div style='text-align:center;'>No data available</div>",
                          }}
                        ></Grid>
                      </Grid>
                    </Paper>
                  );
                })}
            </div>
          </div>
        </MyRoute>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uploadingStatus: state.reducerBillUploadProgress.uploadingStatus,
  };
};

const mapDispatchToProps = {
  changeActiveTab,
  logoutUser,
  showLoader,
};
export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(FAQsComponent)
);
